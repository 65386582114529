export default function useStatus(data) {
  const sStatusStr = (data?.oStatus?.sValue || data?.sStatusStr || '')?.toLowerCase()
  const sFormatStr = (data?.oFormat?.sValue || data?.sFormatStr || '')?.toLowerCase()
  const sLiveGameStatusStr = (data?.oLiveGameStatus?.sValue || data?.sLiveGameStatusStr || '')?.toLowerCase()
  const sFormat = data?.oFormat?.sText || sFormatStr
  const sStatus = data?.oStatus?.sText || sStatusStr
  const sLiveGameStatus = data?.oLiveGameStatus?.sText || sLiveGameStatusStr
  const nLatestInningNumber = data?.nLatestInningNumber
  const conditions = {
    live: sLiveGameStatus
  }
  return { sStatus: conditions?.[sStatusStr] || sStatus, sStatusStr, sLiveGameStatusStr, sFormatStr, sFormat, sLiveGameStatus, nLatestInningNumber }
}
